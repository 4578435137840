<template>
    <ListViewTemplate
        view-name="targetCustomer"
        :items="targetCustomers"
        :is-loading="isLoading"
        :pageTotalRecords="targetCustomers?.length"
        :recordActivePage="1"
        :onClickItem="(item) => onClickItem(item)"
        :getPageData="onInitialize"
        :toggleSlideover="(val) => (isShow = val)"
        :listHeader="targetCustomerHeader"
        :is-show="isShow"
        :emptyViewBinding="emptyViewBinding"
    >
        <template v-slot:add>
            <WithNoSwiper
                v-bind="{
                    isShow: isShow,
                    isSaving: isSaving,
                    list: form,
                    modelKey: 'modelField',
                    formData: formData,
                    ...c.content.addItem,
                }"
                :saveForm="saveForm"
                @onClose="
                    () => {
                        isShow = false;
                    }
                "
            />
        </template>
    </ListViewTemplate>
</template>

<script>
import ListViewTemplate from "@/components/organisms/ListViewItem/ListViewItem";
import { c } from "@/components/constants";
import { targetCustomerForm } from "../../.storybook/sampleData";
import WithNoSwiper from "@/components/organisms/Modals/WithNoSwiper/WithNoSwiper";
import { json } from "overmind";
import { lowerFirst, upperFirst } from "lodash";

export default {
    components: {
        WithNoSwiper,
        ListViewTemplate,
    },
    data() {
        return {
            isLoading: true,
            isShow: false,
            targetCustomerHeader: c.content.listHeader,
            c,
            emptyViewBinding: {
                title: "Get started by adding your first target customer!",
                description: "Once your add a customer, it will show up here",
                btnText: "+ Add Customer",
                icon: "UsersIcon",
            },
            targetCustomers: [],
            isSaving: false,
            form: targetCustomerForm,
            formData: {},
        };
    },
    methods: {
        onClickItem(targetCustomer) {
            this.$router.push({
                name: "Venture Target Customer Detail",
                params: {
                    ventureId: this.$route?.params?.ventureId,
                    id: targetCustomer.id,
                },
            });
        },
        async saveForm(data) {
            console.log(data, "data");
            if (this.isSaving) return false;
            try {
                this.isSaving = true;
                const tagConnect = [],
                    tagCreate = [],
                    tagSearch = [];
                // age
                data?.age?.map((age) => {
                    if (age?.id && isNaN(age?.id)) {
                        tagConnect.push({ id: age?.id });
                        tagSearch.push({
                            nameLower: age?.name?.toLowerCase(),
                            status: age?.status,
                            targetType: age?.targetType,
                            type: "DEMOGRAPHIC",
                            category: age?.category,
                        });
                    } else {
                        // create a new tag
                        tagCreate.push({
                            type: "DEMOGRAPHIC",
                            category: "AGE",
                            name: age?.name,
                            nameLower: age?.name.toLowerCase(),
                            status: "NEW",
                            targetType: "INDIVIDUALS",
                        });
                        tagSearch.push({
                            nameLower: age?.name?.toLowerCase(),
                            status: "NEW",
                            targetType: "INDIVIDUALS",
                            type: "DEMOGRAPHIC",
                            category: "AGE",
                        });
                    }
                });
                // gender
                data?.gender?.map((gender) => {
                    if (gender?.id && isNaN(gender?.id)) {
                        tagConnect.push({ id: gender?.id });
                        tagSearch.push({
                            nameLower: gender?.name?.toLowerCase(),
                            status: gender?.status,
                            targetType: gender?.targetType,
                            type: "DEMOGRAPHIC",
                            category: gender?.category,
                        });
                    } else {
                        // create a new tag
                        tagCreate.push({
                            type: "DEMOGRAPHIC",
                            category: "GENDER",
                            name: gender?.name,
                            nameLower: gender?.name.toLowerCase(),
                            status: "NEW",
                            targetType: "INDIVIDUALS",
                        });
                        tagSearch.push({
                            nameLower: gender?.name?.toLowerCase(),
                            status: "NEW",
                            targetType: "INDIVIDUALS",
                            type: "DEMOGRAPHIC",
                            category: "GENDER",
                        });
                    }
                });
                // race
                data?.ethnicity?.map((ethnicity) => {
                    if (ethnicity?.id && isNaN(ethnicity?.id)) {
                        tagConnect.push({ id: ethnicity?.id });
                        tagSearch.push({
                            nameLower: ethnicity?.name?.toLowerCase(),
                            status: ethnicity?.status,
                            targetType: ethnicity?.targetType,
                            type: "DEMOGRAPHIC",
                            category: ethnicity?.category,
                        });
                    } else {
                        // create a new tag
                        tagCreate.push({
                            type: "DEMOGRAPHIC",
                            category: "ETHNICITY",
                            name: ethnicity?.name,
                            nameLower: ethnicity?.name.toLowerCase(),
                            status: "NEW",
                            targetType: "INDIVIDUALS",
                        });
                        tagSearch.push({
                            nameLower: ethnicity?.name?.toLowerCase(),
                            status: "NEW",
                            targetType: "INDIVIDUALS",
                            type: "DEMOGRAPHIC",
                            category: "ETHNICITY",
                        });
                    }
                });
                // income
                data?.income?.map((income) => {
                    if (income?.id && isNaN(income?.id)) {
                        tagConnect.push({ id: income?.id });
                        tagSearch.push({
                            nameLower: income?.name?.toLowerCase(),
                            status: income?.status,
                            targetType: income?.targetType,
                            type: "DEMOGRAPHIC",
                            category: income?.category,
                        });
                    } else {
                        // create a new tag
                        tagCreate.push({
                            type: "DEMOGRAPHIC",
                            category: "INCOME",
                            name: income?.name,
                            nameLower: income?.name.toLowerCase(),
                            status: "NEW",
                            targetType: "INDIVIDUALS",
                        });
                        tagSearch.push({
                            nameLower: income?.name?.toLowerCase(),
                            status: "NEW",
                            targetType: "INDIVIDUALS",
                            type: "DEMOGRAPHIC",
                            category: "INCOME",
                        });
                    }
                });
                // revenue
                data?.revenue?.map((revenue) => {
                    if (revenue?.id && isNaN(revenue?.id)) {
                        tagConnect.push({ id: revenue?.id });
                        tagSearch.push({
                            nameLower: revenue?.name?.toLowerCase(),
                            status: revenue?.status,
                            targetType: revenue?.targetType,
                            type: "DEMOGRAPHIC",
                            category: revenue?.category,
                        });
                    } else {
                        // create a new tag
                        tagCreate.push({
                            type: "DEMOGRAPHIC",
                            category: "REVENUE",
                            name: revenue?.name,
                            nameLower: revenue?.name.toLowerCase(),
                            status: "NEW",
                            targetType: "INDIVIDUALS",
                        });
                        tagSearch.push({
                            nameLower: revenue?.name?.toLowerCase(),
                            status: "NEW",
                            targetType: "INDIVIDUALS",
                            type: "DEMOGRAPHIC",
                            category: "REVENUE",
                        });
                    }
                });
                // employee_count
                data?.employeeCount?.map((employee_count) => {
                    if (employee_count?.id && isNaN(employee_count?.id)) {
                        tagConnect.push({ id: employee_count?.id });
                        tagSearch.push({
                            nameLower: employee_count?.name?.toLowerCase(),
                            status: employee_count?.status,
                            targetType: employee_count?.targetType,
                            type: "DEMOGRAPHIC",
                            category: employee_count?.category,
                        });
                    } else {
                        // create a new tag
                        tagCreate.push({
                            type: "DEMOGRAPHIC",
                            category: "EMPLOYEE_COUNT",
                            name: employee_count?.name,
                            nameLower: employee_count?.name.toLowerCase(),
                            status: "NEW",
                            targetType: "INDIVIDUALS",
                        });
                        tagSearch.push({
                            nameLower: employee_count?.name?.toLowerCase(),
                            status: "NEW",
                            targetType: "INDIVIDUALS",
                            type: "DEMOGRAPHIC",
                            category: "EMPLOYEE_COUNT",
                        });
                    }
                });
                // location
                if (data?.location && data?.location?.length > 0) {
                    for (let i = 0; i < data?.location?.length; i++) {
                        const lct = data?.location[i];
                        const res =
                            await this.actions.google.searchGooglePlaceById({
                                placeId: lct?.place_id,
                            });
                        var city;
                        var state;
                        var country;
                        res?.address_components?.map((addressComponent) => {
                            if (
                                _.indexOf(
                                    addressComponent.types,
                                    "neighborhood"
                                ) > -1
                            )
                                city = addressComponent["short_name"];
                            if (
                                _.indexOf(addressComponent.types, "locality") >
                                -1
                            )
                                city = addressComponent["short_name"];
                            if (
                                _.indexOf(
                                    addressComponent.types,
                                    "administrative_area_level_1"
                                ) > -1
                            )
                                state = addressComponent["short_name"];
                            if (
                                _.indexOf(addressComponent.types, "country") >
                                -1
                            )
                                country = addressComponent["short_name"];
                        });

                        if (city) {
                            const item = this.state.tag.tags.find(
                                (t) => t?.name === city
                            );
                            const update = {
                                type: "LOCATION",
                                category: "CITY",
                                name: city,
                                nameLower: city.toLowerCase(),
                                status: "APPROVED",
                                targetType: "ALL",
                            };
                            if (item?.id) {
                                tagConnect.push({ id: item?.id });
                            } else {
                                tagCreate.push(update);
                            }
                            tagSearch.push({
                                nameLower: city?.toLowerCase(),
                                status: "APPROVED",
                                targetType: "ALL",
                                type: "LOCATION",
                                category: "CITY",
                            });
                        }
                        if (state) {
                            const item = this.state.tag.tags.find(
                                (t) => t?.name === state
                            );
                            const update = {
                                type: "LOCATION",
                                category: "STATE",
                                name: state,
                                nameLower: state.toLowerCase(),
                                status: "APPROVED",
                                targetType: "ALL",
                            };
                            if (item?.id) {
                                tagConnect.push({ id: item?.id });
                            } else {
                                tagCreate.push(update);
                            }
                            tagSearch.push({
                                nameLower: state?.toLowerCase(),
                                status: "APPROVED",
                                targetType: "ALL",
                                type: "LOCATION",
                                category: "STATE",
                            });
                        }
                        if (country) {
                            const item = this.state.tag.tags.find(
                                (t) => t?.name === country
                            );
                            const update = {
                                type: "LOCATION",
                                category: "COUNTRY",
                                name: country,
                                nameLower: country.toLowerCase(),
                                status: "APPROVED",
                                targetType: "ALL",
                            };
                            if (item?.id) {
                                tagConnect.push({ id: item?.id });
                            } else {
                                tagCreate.push(update);
                            }
                            tagSearch.push({
                                nameLower: country?.toLowerCase(),
                                status: "APPROVED",
                                targetType: "ALL",
                                type: "LOCATION",
                                category: "COUNTRY",
                            });
                        }
                    }
                }
                if (data?.interest && data?.interest?.length > 0)
                    data?.interest?.map((interest) => {
                        if (interest?.id && isNaN(interest?.id)) {
                            tagConnect.push({ id: interest?.id });
                            tagSearch.push({
                                nameLower: interest?.name?.toLowerCase(),
                                status: interest?.status,
                                targetType: interest?.targetType,
                                type: "DEMOGRAPHIC",
                                category: interest?.category,
                            });
                        } else {
                            tagCreate.push({
                                type: "DEMOGRAPHIC",
                                category:
                                    interest?.selectedOption === "Interest"
                                        ? "DESCRIPTORS"
                                        : "DESCRIPTORS",
                                name: interest?.name,
                                targetType: "INDIVIDUALS",
                                status: "NEW",
                            });
                            tagSearch.push({
                                nameLower: interest?.name?.toLowerCase(),
                                status: "NEW",
                                targetType: "INDIVIDUALS",
                                type: "DEMOGRAPHIC",
                                category:
                                    interest?.selectedOption === "Interest"
                                        ? "DESCRIPTORS"
                                        : "DESCRIPTORS",
                            });
                        }
                    });
                // First check if this TargetCustomer already exists
                const { doesTargetCustomerExist } =
                    await this.actions.targetCustomer.doesTargetCustomerExist({
                        type: data?.type?.name,
                        tags: tagSearch,
                    });
                await this.actions.venture.saveVenture({
                    where: { id: this.$route.params?.ventureId },
                    data: {
                        targetCustomerUsers: {
                            create: [
                                {
                                    name: data?.name,
                                    emailTemplates: {
                                        create: c.emailTemplates,
                                    },
                                    interviewScripts: {
                                        create: [
                                            {
                                                ...c.interviewScript,
                                                createdBy: {
                                                    connect: {
                                                        id: this.state
                                                            .currentUser?.id,
                                                    },
                                                },
                                            },
                                        ],
                                    },
                                    targetCustomer: doesTargetCustomerExist?.id
                                        ? {
                                              connect: {
                                                  id: doesTargetCustomerExist?.id,
                                              },
                                          }
                                        : {
                                              create: {
                                                  name: lowerFirst(data?.name),
                                                  tags: {
                                                      create: tagCreate,
                                                      connect: tagConnect,
                                                  },
                                                  type: data?.type?.name,
                                              },
                                          },
                                    createdBy: {
                                        connect: {
                                            id: this.state.currentUser?.id,
                                        },
                                    },
                                    researchPrompt: data?.socialLink,
                                },
                            ],
                        },
                    },
                });
                const ventures = await this.actions.venture.getVentures({
                    where: { id: this.$route.params?.ventureId },
                    getValues: true,
                    all: true,
                    query: "ventureTargetCustomers",
                });
                this.venture = ventures[0];
                this.onInitialize();
                const venture = await this.actions.venture.getVentureById(
                    this.$route.params?.ventureId
                );
                let surveyData = [];
                for (
                    let i = 0;
                    i <
                    venture.targetCustomerUsers?.filter((t) => !t?.isArchived)
                        ?.length;
                    i++
                ) {
                    const targetCustomer = venture.targetCustomerUsers?.filter(
                        (t) => !t?.isArchived
                    )[i];
                    const data = await this.actions.userSurvey.getUserSurveys({
                        where: {
                            isArchived_not: true,
                            survey: {
                                targetCustomerUser: { id: targetCustomer?.id },
                            },
                        },
                        getValues: true,
                    });
                    console.log(data, "data");
                    surveyData = [...surveyData, ...data];
                }
                venture.surveyData = surveyData;
                this.actions.setCurrentVenture(venture);
                this.isShow = false;
            } catch (e) {
                console.log(e);
            } finally {
                this.isSaving = false;
            }
        },
        getInterviewsCount(targetCustomerUser) {
            let length = 0;
            targetCustomerUser?.contacts?.map(
                (c) => (length += c?.interviews?.length)
            );
            return length;
        },
        onInitialize() {
            this.targetCustomers = _.map(
                this.venture.targetCustomerUsers?.filter((t) => !t?.isArchived),
                (targetCustomerUser) => {
                    return {
                        id: targetCustomerUser?.id,
                        title: upperFirst(targetCustomerUser?.name || ""),
                        description:
                            targetCustomerUser?.targetCustomer?.type || "",
                        actionLabel:
                            targetCustomerUser?.targetCustomer?.type || "",
                        subtitles: [
                            {
                                icon: "ExclamationIcon",
                                name: "Opportunities",
                                count:
                                    targetCustomerUser?.opportunities?.filter(
                                        (o) => !o?.isArchived
                                    )?.length || 0,
                            },
                            {
                                icon: "UsersIcon",
                                name: "Contacts",
                                count: targetCustomerUser?.contacts?.filter(
                                    (o) => !o?.isArchived
                                )?.length,
                            },
                            {
                                name: "Interviews",
                                count: this.getInterviewsCount(
                                    targetCustomerUser
                                ),
                                icon: "ChatAlt2Icon",
                            },
                            {
                                name: "Surveys",
                                count: targetCustomerUser?.surveys?.length,
                                icon: "ServerIcon",
                            },
                        ],
                    };
                }
            );
        },
    },
    async created() {
        await this.actions.google.searchGooglePlaces({
            keyword: "United States",
        });
        this.formData.location = json(this.state.google.placeResults);
        const ventures = await this.actions.venture.getVentures({
            where: { id: this.$route.params?.ventureId },
            getValues: true,
            all: true,
            query: "ventureTargetCustomers",
        });
        this.venture = ventures[0];
        // this.actions.setCurrentVenture(this.venture);
        this.onInitialize();
        await this.actions.tag.getTags({
            all: true,
            where: { status: "APPROVED", name_not: "Prefer not to say" },
        });
        console.log(this.state.tag.tags, "tags", this.form);
        const ages = [],
            genders = [],
            incomes = [],
            locations = [],
            ethnicities = [],
            interests = [],
            revenues = [],
            employee_counts = [];
        json(this.state.tag.tags)
            .filter((t) => t.category === "AGE")
            ?.map((t) => {
                ages.push({ ...t, label: t.name });
            });
        json(this.state.tag.tags)
            .filter((t) => t.category === "GENDER")
            ?.map((t) => {
                genders.push({ ...t, label: t.name });
            });
        json(this.state.tag.tags)
            .filter((t) => t.category === "INCOME")
            ?.map((t) => {
                incomes.push({ ...t, label: t.name });
            });
        json(this.state.tag.tags)
            .filter((t) => t.type === "LOCATION")
            ?.map((t) => {
                locations.push({ ...t, label: t.name });
            });
        json(this.state.tag.tags)
            .filter((t) => t.category === "ETHNICITY")
            ?.map((t) => {
                ethnicities.push({ ...t, label: t.name });
            });
        json(this.state.tag.tags)
            .filter((t) => t.category === "DESCRIPTORS")
            ?.map((t) => {
                interests.push({ ...t, label: t.name });
            });
        json(this.state.tag.tags)
            .filter((t) => t.category === "REVENUE")
            ?.map((t) => {
                revenues.push({ ...t, label: t.name });
            });
        json(this.state.tag.tags)
            .filter((t) => t.category === "EMPLOYEE_COUNT")
            ?.map((t) => {
                employee_counts.push({ ...t, label: t.name });
            });
        this.form[0].fields[6].options = JSON.stringify(ages);
        this.form[0].fields[7].options = JSON.stringify(genders);
        this.form[0].fields[9].options = JSON.stringify(incomes);
        this.form[0].fields[10].options = JSON.stringify(locations);
        this.form[0].fields[8].options = JSON.stringify(ethnicities);
        this.form[0].fields[5].options = JSON.stringify(
            interests?.filter((i) => i?.status === "APPROVED")
        );
        this.form[0].fields[13].options = JSON.stringify(
            revenues?.sort((a, b) => (a?.sortOrder > b?.sortOrder ? 1 : -1))
        );
        this.form[0].fields[14].options = JSON.stringify(employee_counts);
        this.form[0].fields[23].options = JSON.stringify(
            revenues?.sort((a, b) => (a?.sortOrder > b?.sortOrder ? 1 : -1))
        );
        this.form[0].fields[24].options = JSON.stringify(employee_counts);
    },
};
</script>

<style scoped></style>
